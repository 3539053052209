// Verzió: 1.2.0-06 2024-10-30
export const fetchDataFromServer = async (apiKey, variable, method = 'GET', value = '') => {
  let url = 'https://hacsi.hu/saveData.php';
  
  // Ha a metódus GET, akkor adjuk hozzá a paramétereket az URL-hez
  if (method === 'GET') {
    url += `?apikey=${apiKey}&var=${variable}`;
  }

  const params = new URLSearchParams({
    apikey: apiKey,
    var: variable,
    value: value,
  });

  try {
    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: method === 'POST' ? params.toString() : undefined,
    });
    return response.json();
  } catch (error) {
    console.error('Szerver hiba:', error);
    return { error: error.message };
  }
};
