// Version 1.2.0-04 2024-10-29
import React from 'react';

const SwitchComponent = ({ label, value, onToggle, onColor, offColor }) => (
  <div className="switchContainer">
    <p className="switchText">{label}</p>
    <input
      type="checkbox"
      checked={value}
      onChange={onToggle}
      style={{ accentColor: value ? onColor : offColor }}
      className="customSwitch"
    />
  </div>
);

export default SwitchComponent;
