// Version 1.0.7 2024-10-31
import React, { useState, useEffect } from 'react';

const VendegLap1 = () => {
  const [lampState, setLampState] = useState(false);
  const [autoLampState, setAutoLampState] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [heartbeat, setHeartbeat] = useState(0);

  const fetchLampStates = () => {
    // Lámpa állapot lekérése
    fetch('https://fra1.blynk.cloud/external/api/get?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v0')
      .then(response => response.text())
      .then(data => setLampState(data === '1'))
      .catch(error => console.error('Hiba a lámpa állapotának lekérésében:', error));

    // Auto_lamp állapot lekérése
    fetch('https://fra1.blynk.cloud/external/api/get?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v5')
      .then(response => response.text())
      .then(data => setAutoLampState(data === '1'))
      .catch(error => console.error('Hiba az Auto_lamp állapotának lekérésében:', error));

    // Lámpa hátralévő világítási idejének lekérése
    fetch('https://fra1.blynk.cloud/external/api/get?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v6')
      .then(response => response.text())
      .then(data => setRemainingTime(data))
      .catch(error => console.error('Hiba a lámpa hátralévő idejének lekérésében:', error));

    // Heartbeat (HB) állapot lekérése
    fetch('https://fra1.blynk.cloud/external/api/get?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v8')
      .then(response => response.text())
      .then(data => setHeartbeat(parseInt(data)))
      .catch(error => console.error('Hiba a HeartBeat állapotának lekérésében:', error));
  };

  useEffect(() => {
    // Az első adatok lekérése induláskor
    fetchLampStates();

    // 5 másodpercenkénti frissítés
    const intervalId = setInterval(fetchLampStates, 5000);

    // Takarítás a komponens elhagyásakor
    return () => clearInterval(intervalId);
  }, []);

  const handleClearData = () => {
    localStorage.clear();
    alert('A böngésző tárolt adatai törölve lettek.');
  };

  // Lámpa kapcsoló kezelése
  const toggleLamp = () => {
    const newLampState = !lampState;
    setLampState(newLampState);
    const lampUrl = `https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v0=${newLampState ? 1 : 0}`;
    fetch(lampUrl)
      .then(response => {
        if (!response.ok) throw new Error('Hiba a lámpa állapotának frissítésében.');
      })
      .catch(error => console.error(error));
  };

  // Auto_lamp kapcsoló kezelése
  const toggleAutoLamp = () => {
    const newAutoLampState = !autoLampState;
    setAutoLampState(newAutoLampState);
    const autoLampUrl = `https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v5=${newAutoLampState ? 1 : 0}`;
    fetch(autoLampUrl)
      .then(response => {
        if (!response.ok) throw new Error('Hiba az Auto_lamp állapotának frissítésében.');
      })
      .catch(error => console.error(error));
  };

  // Kapu nyitása
  const openGate = () => {
    const gateUrl = `https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v1=1`;
    fetch(gateUrl)
      .then(response => {
        if (!response.ok) throw new Error('Hiba a kapu nyitásakor.');
      })
      .catch(error => console.error(error));
  };

  return (
    <div className="pageContainer">
      <div className="headerRow">
        <button className="clearButton" onClick={handleClearData}>Törlés</button>
        <h1 className="centeredTitle">
          Szabadság utca 
          <span className={`heartbeat-dot ${heartbeat === 1 ? 'green' : 'red'}`}></span>
        </h1>
      </div>
      <div className="buttonGrid">
        <button className="button">Gomb 1</button>
        <button className="button" onClick={openGate}>Kapu nyitás</button>
      </div>
      <div className="switchRow">
        <label className="switchContainer">
          Lámpa
          <input type="checkbox" checked={lampState} onChange={toggleLamp} className="customSwitch" />
        </label>
        <label className="switchContainer">
          Auto_lamp
          <input type="checkbox" checked={autoLampState} onChange={toggleAutoLamp} className="customSwitch" />
        </label>
      </div>
      <div className="remainingTimeBox">
        <p>Hátralévő világítási idő: {remainingTime !== null ? `${remainingTime} perc` : 'Betöltés...'}</p>
      </div>
    </div>
  );
};

export default VendegLap1;





// Kikapcsolja a lámpát : https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v0=0 
// Bekapcsolja a lámpát : https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v0=1 
// kapu nyitás          : https://fra1.blynk.cloud/external/api/update?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v1=?
// HB led vissza        : https://fra1.blynk.cloud/external/api/get?token=pEI3Hwp_uLXu00ZCVUFRgjFhFEVbRVUe&v8   0 vagy 1 karakter jön vissza
//
// rtsp://admin:Attila1964@192.168.0.150:554/Streaming/Channels/101
//