// Version 1.2.0-06 2024-10-30
import React, { useState, useEffect } from 'react';
import SwitchComponent from './SwitchComponent';
import { fetchDataFromServer } from '../apiUtils';
import { useNavigate } from 'react-router-dom'; // React Router navigáció

const HegyaljaPage = () => {
  const navigate = useNavigate(); // Navigációs hook
  const [switches, setSwitches] = useState([
    { label: 'Kerti lámpa', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Udvari lámpa', value: false, onColor: '#FF4500', offColor: '#39FF14' },
  ]);
  const [heartbeat, setHeartbeat] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState('');
  const [serverValue, setServerValue] = useState(30);
  const [responseText, setResponseText] = useState('');
  const [temperature, setTemperature] = useState(null);

  const toggleSwitch = (index) => {
    const updatedSwitches = switches.map((sw, i) => (i === index ? { ...sw, value: !sw.value } : sw));
    setSwitches(updatedSwitches);
    sendDataToServer(index === 0 ? 'HE_lamp_kert' : 'HE_lamp_udvar', updatedSwitches[index].value ? 'true' : 'false');
  };

  const changeValue = (amount) => {
    const newValue = Math.max(0, serverValue + amount);
    setServerValue(newValue);
    sendDataToServer('security_code', newValue);
  };

  const sendDataToServer = (variable, value) => {
    fetchDataFromServer('API-002', variable, 'POST', value)
      .then((response) => {
        if (response) {
          setResponseText(`Válasz: ${JSON.stringify(response)}`);
        }
      })
      .catch((error) => {
        setResponseText(`Hiba: ${error.message}`);
      });
  };

  const fetchHeartbeat = () => {
    fetchDataFromServer('API-002', 'HeartBeat', 'GET')
      .then((response) => {
        if (response && response.HeartBeat && response.HeartBeat.value !== undefined) {
          setHeartbeat(response.HeartBeat.value === 'true');
        }
        setLastRequestTime(new Date().toLocaleString());
        console.log('HeartBeat response:', response); // Debug log hozzáadva
      })
      .catch((error) => {
        console.error('Hiba a HeartBeat lekérdezésekor:', error);
      });
  };

  const fetchInitialState = () => {
    fetchDataFromServer('API-002', '', 'POST')
      .then((response) => {
        if (response) {
          setSwitches((prev) =>
            prev.map((sw, i) => ({
              ...sw,
              value: response[i === 0 ? 'HE_lamp_kert' : 'HE_lamp_udvar']?.value === 'true',
            }))
          );
          setServerValue(parseInt(response.security_code?.value, 10) || 0);
          setHeartbeat(response.HeartBeat?.value === 'true');
          if (response.HE_temp_1 !== undefined) {
            setTemperature(parseFloat(response.HE_temp_1.value));
          }
        }
      })
      .catch((error) => {
        console.error('Hiba a jelenlegi állapotok lekérdezésekor:', error);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(fetchHeartbeat, 2000);
    fetchInitialState();
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="pageContainer">
      <div className="headerRow">
        <button className="backButton" onClick={() => navigate('/')}>Vissza</button>
        <h2 className="text">Hegyalja utca</h2>
        <div className={`lamp ${heartbeat ? 'active' : ''}`}></div>
      </div>
      <div className="row">
        {switches.map((sw, i) => (
          <SwitchComponent key={i} {...sw} onToggle={() => toggleSwitch(i)} />
        ))}
      </div>
      <div className="securityCodeRow">
        <h2>Biztonsági kód</h2>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(-1)} className="counterButtonSmall">-</button>
        </div>
        <p className="counterTextSmall">{serverValue}</p>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(1)} className="counterButtonSmall">+</button>
        </div>
      </div>
      <div className="temperatureContainer">
        <div className="temperatureBox">
          <h3>Hőmérséklet</h3>
          <p>{temperature !== null ? `${temperature} °C` : 'N/A'}</p>
        </div>
      </div>
      <div className="responseContainer">
        <p className="responseText">{responseText}</p>
        <p className="responseText">Lekér: {lastRequestTime}</p>
      </div>
    </div>
  );
};

export default HegyaljaPage;
