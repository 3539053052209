// Version 1.2.0-05 2024-10-30
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import SwitchesPage from './components/SwitchesPage';
import HegyaljaPage from './components/HegyaljaPage';
import VendegLap1 from './components/VendegLap1'; // Külön fájlból importálva

// Bejelentkezési komponens, amely hitelesíti a felhasználót
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accessCode, setAccessCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'AdmiN' && password === 'AquaSanitas') {
      if (accessCode === '3645') {
        localStorage.setItem('authData', JSON.stringify({ username, password, accessCode }));
        onLogin('home');
      } else if (accessCode === '1645') {
        localStorage.setItem('authData', JSON.stringify({ username, password, accessCode }));
        onLogin('guest');
      } else {
        alert('Hibás kód');
      }
    } else {
      alert('Hibás bejelentkezési adatok');
    }
  };

  useEffect(() => {
    const savedAuthData = localStorage.getItem('authData');
    if (savedAuthData) {
      const { username, password, accessCode } = JSON.parse(savedAuthData);
      if (username === 'AdmiN' && password === 'AquaSanitas') {
        if (accessCode === '3645') {
          onLogin('home');
        } else if (accessCode === '1645') {
          onLogin('guest');
        }
      }
    }
  }, [onLogin]);

  return (
    <form onSubmit={handleSubmit} className="loginForm">
      <label>
        Felhasználónév:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <br />
      <label>
        Jelszó:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </label>
      <br />
      <label>
        Kód:
        <input type="text" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
      </label>
      <br />
      <button type="submit">Bejelentkezés</button>
    </form>
  );
};

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const savedAuthData = localStorage.getItem('authData');
    if (savedAuthData) {
      const { username, password, accessCode } = JSON.parse(savedAuthData);
      if (username === 'AdmiN' && password === 'AquaSanitas') {
        if (accessCode === '3645') {
          setUserRole('home');
          setLoggedIn(true);
        } else if (accessCode === '1645') {
          setUserRole('guest');
          setLoggedIn(true);
        }
      }
    }
  }, []);

  if (!loggedIn) {
    return <Login onLogin={(role) => { setUserRole(role); setLoggedIn(true); }} />;
  }

  return (
    <Router>
      <Routes>
        {userRole === 'home' ? (
          <Route path="/" element={<HomePage />} />
        ) : (
          <Route path="/" element={<VendegLap1 />} />
        )}
        <Route path="/switches" element={<SwitchesPage apiKey="API-001" />} />
        <Route path="/hegyalja" element={<HegyaljaPage apiKey="API-002" />} />
      </Routes>
    </Router>
  );
};

export default App;


/*
Módosítások:
Felhasználónév: admin helyett AdmiN
Jelszó: secret helyett AquaSanitas
Access kódok:
1234 helyett 3645 (home)
1235 helyett 1645 (guest)
*/

